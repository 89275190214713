<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-75"
    menu-class="dropdown-menu-media dropdown-menu-right"
    left
  >
    <template #button-content>
      <div :class="['d-flex justify-content-center', { 'mt-1': isOnCall }]">
        <div class="d-flex align-items-center justify-content-center">
          <feather-icon
            :badge="calls.length"
            badge-classes="bg-danger"
            :stroke="iconColor"
            icon="PhoneCallIcon"
            size="21"
            :class="[
              'text-body btn-toggle-config-mode',
              { 'on-call': isOnCall },
              { ringing: isRinging },
              { ringing: isCalling },
            ]"
          />
        </div>

        <b-badge pill variant="warning" v-if="isOnCall" class="ml-1 p-1">
          {{ $t("sip.dropdown.on_call_with") + " " }}
          {{ unit_user_caller }}
        </b-badge>
        <b-badge pill variant="warning" v-if="isOnCall" class="ml-1 p-1">
          {{ hours + ":" + minutes + ":" + seconds }}
        </b-badge>
        <b-badge
          pill
          variant="warning"
          v-if="isOnCall && isHolded"
          class="ml-1 p-1 on-call"
        >
          {{ $t("sip.dropdown.holded") }}
        </b-badge>
        <b-button
          variant="danger"
          @click="hangUp"
          size="sm"
          v-if="isOnCall || isCalling"
          class="mx-1"
          >{{ $t("sip.dropdown.hang_up") }}
        </b-button>
        <div
          class="d-flex align-items-center justify-content-between mx-1"
          v-if="isRinging"
        >
          <span class="font-weight-bolder">
            {{
              $t("sip.dropdown.incoming_call") +
              ` ${callRecieved.callingPartyNumber}`
            }}
          </span>

          <b-button
            class="float-right"
            pill
            size="sm"
            variant="success"
            @click="answerCall()"
            v-if="!isOnCall"
            >{{ $t("sip.dropdown.answer_call") }}r</b-button
          >
          <b-button
            class="float-right mr-1"
            pill
            size="sm"
            variant="danger"
            @click="hangUp()"
            v-if="!isOnCall"
            >{{ $t("sip.dropdown.hang_up") }}</b-button
          >
        </div>
      </div>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t("sip.dropdown.calls_title") }}
        </h4>
        <b-badge pill variant="light-primary">
          {{ `${calls.length} ` + $t("sip.dropdown.calls_bandage") }}</b-badge
        >
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-media v-if="isRinging">
        <template #aside>
          <b-avatar
            size="32"
            text="CALL"
            :style="{ backgroundColor: 'green' }"
            :class="['btn-toggle-config-mode', { 'on-call': isRinging }]"
          >
          </b-avatar>
        </template>
        <p class="media-heading"></p>
        <span class="font-weight-bolder">
          {{
            $t("sip.dropdown.incoming_call") +
            ` ${callRecieved.callingPartyNumber}`
          }}
        </span>

        <b-button
          class="float-right"
          pill
          size="sm"
          variant="success"
          @click="answerCall()"
          v-if="!isOnCall"
          >{{ $t("sip.dropdown.answer_call") }}r</b-button
        >
        <b-button
          class="float-right mr-1"
          pill
          size="sm"
          variant="danger"
          @click="hangUp()"
          v-if="!isOnCall"
          >{{ $t("sip.dropdown.hang_up") }}</b-button
        >
      </b-media>

      <!--Calls List -->
      <b-link v-for="call in calls" :key="call.sessionId">
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              text="IVR"
              :style="{ backgroundColor: 'green' }"
            >
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder" v-if="call.alarm">
              {{
                `${call.alarm.router.unit.description} - Ext. ${call.callingPartyNumber}`
              }}
              <small class="notification-text float-right">{{
                formatDateAssigned(call.created_at)
              }}</small>
            </span>
            <span class="font-weight-bolder" v-if="!call.alarm">
              {{ `Ext. ${call.callingPartyNumber}` }}
              <small class="notification-text float-right">{{
                formatDateAssigned(call.created_at)
              }}</small>
            </span>
          </p>

          <small class="notification-text mr-1">{{ call.sessionId }}</small>

          <b-button
            class="float-right"
            pill
            size="sm"
            variant="success"
            @click="
              takeCall(call.sessionId, call.callingPartyNumber, call.alarm)
            "
            v-if="
              !isOnCall &&
              !isCalling &&
              isConnected == 'Connected' &&
              !lockAnswerCall
            "
          >
            {{ $t("sip.dropdown.answer_call") }}</b-button
          >

          <b-button
            class="float-right"
            pill
            size="sm"
            variant="success"
            disabled
            @click="
              takeCall(call.sessionId, call.callingPartyNumber, call.alarm)
            "
            v-if="lockAnswerCall"
          >
            <feather-icon icon="loader" size="16" />
            {{ $t("sip.dropdown.transfering_call") }}
          </b-button>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <div class="d-flex justify-content-between">
        <h5 class="">
          Ext: {{ deviceInterphone.device_id }} - {{ isConnected }}
          <b-button
            v-if="
              isConnected != 'Connected' &&
              $can('force_connect', 'DeviceInterphone')
            "
            class="m-1 btn-sm"
            variant="success"
            @click="forceConnectSIP"
            >{{ $t("sip.dropdown.connect") }}</b-button
          >
          <b-button
            v-if="
              isConnected == 'Connected' &&
              $can('force_disconnect', 'DeviceInterphone')
            "
            class="m-1 btn-sm"
            variant="danger"
            @click="disconnectSIP"
            >{{ $t("sip.dropdown.disconnect") }}</b-button
          >
        </h5>

        <b-badge pill variant="light-success" v-if="isOnCall">
          {{ $t("sip.dropdown.on_call_with") + " " }}
          {{
            callRecieved.callingPartyNumber == ""
              ? callingNumber
              : callRecieved.callingPartyNumber
          }}
        </b-badge>

        <!-- <b-button variant="light" @click="disconnectSIP">Desconectar</b-button> -->
        <b-badge class="on-call" pill variant="light-warning" v-if="isCalling">
          {{ $t("sip.dropdown.calling_to") + " " + callingNumber }}
        </b-badge>
      </div>
      <div
        class="d-flex justify-content-center"
        v-if="isOnCall && presavedAlarm.router"
      >
        <b-badge pill variant="light-danger" class="m-1">
          {{
            $t("sip.dropdown.alarm_from") +
            " " +
            presavedAlarm.alarmJson.unit_name
          }}
        </b-badge>

        <b-badge pill variant="dark" class="m-1">
          {{ presavedAlarm.alarmJson.alarm_acronym }}
        </b-badge>
      </div>
      <div
        class="d-flex justify-content-center"
        v-if="isOnCall && presavedAlarm.alarmJson"
      >
        <b-button
          class="m-1 btn-sm"
          variant="success"
          @click="alarmAction(presavedAlarm, 'confirmed')"
          v-if="presavedAlarm.alarmJson.alarm_status != 'attending'"
          >{{ $t("grid.slots.info.btns.confirmed") }}</b-button
        >

        <b-button
          class="m-1 btn-sm"
          variant="success"
          @click="alarmAction(presavedAlarm, 'attended')"
          v-if="presavedAlarm.alarmJson.alarm_status == 'attending'"
          >{{ $t("grid.slots.info.btns.attended") }}</b-button
        >

        <b-button
          class="m-1 btn-sm"
          variant="danger"
          @click="alarmAction(presavedAlarm, 'canceled')"
          >{{ $t("grid.slots.info.btns.cancel") }}</b-button
        >

        <b-button
          class="m-1 btn-sm"
          variant="light"
          @click="alarmShowRow(presavedAlarm)"
          >{{ $t("sip.dropdown.show_alarm") }}</b-button
        >

        <b-button
          class="m-1 btn-sm"
          variant="info"
          @click="alarmShowConfig(presavedAlarm)"
          >{{ $t("grid.widgets.alarms.menu.display") }}</b-button
        >
      </div>

      <div class="d-flex justify-content-between mt-1">
        <audio id="remoteAudio" controls>
          <p>Your browser doesn't support HTML5 audio.</p>
        </audio>
        <b-button
          class="mx-1"
          variant="light"
          @click="mute"
          v-if="isOnCall || isCalling"
        >
          <feather-icon :icon="isMuted ? 'MicOffIcon' : 'MicIcon'" size="16" />
        </b-button>
        <b-button
          variant="danger"
          @click="hangUp"
          v-if="isOnCall || isCalling"
          >{{ $t("sip.dropdown.hang_up") }}</b-button
        >
        <b-button
          class="m-1"
          variant="warning"
          @click="holdSession"
          v-if="isOnCall && !isHolded && UserData.is_master_admin"
          >{{ $t("sip.dropdown.hold") }}</b-button
        >

        <b-button
          class="m-1"
          variant="warning"
          @click="unHoldSession"
          v-if="isHolded && UserData.is_master_admin"
          >{{ $t("sip.dropdown.unhold") }}</b-button
        >

        <b-button
          class="m-1 btn-sm"
          variant="info"
          @click="activateRelay()"
          v-if="UserData.is_master_admin"
          >Relay</b-button
        >
        <div v-if="UserData.is_master_admin">
          <b-form-input
            id="transferUser"
            v-model="transferUser"
            trim
            v-if="UserData.is_master_admin"
          />
          <b-button
            class="m-1 btn-sm"
            variant="info"
            @click="transferCall()"
            v-if="UserData.is_master_admin"
            >Transfer</b-button
          >
        </div>
      </div>
    </li>
  </b-nav-item-dropdown>
  <!-- v-if="UserData.is_master_admin" -->
</template>

<script>
import { Web, URI } from "sip.js";
const { SimpleUser, simpleUserDelegate, RegistererUnregisterOptions } = Web;
import { EventBus } from "@/libs/event-bus";
import EventEmitter from "@/libs/DOMEventEmitter";

import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import {
  computed,
  watch,
  ref,
  onMounted,
  onUnmounted,
} from "@vue/composition-api";
import { props } from "vue2-dropzone";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { useToast } from "vue-toastification/composition";
import { BDropdownDivider } from "bootstrap-vue";
import moment from "moment-timezone";
import { t } from "@/@core/libs/i18n/utils";
import axios from "@axios";

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    UserData: {
      type: Object,
      required: false,
    },
  },
  setup(props) {
    //ref to validate the update of this one
    const shouldUpdate = ref(true);
    const shouldDisconnect = ref(true);
    //component refs
    const calls = computed(() => store.getters["sip/getItems"]);
    const deviceInterphone = props.UserData.device_interphone;
    // const deviceInterphoneRef = ref(props.UserData.device_interphone)
    const iconColor = ref("white");
    const isOnCall = ref(false);
    const isRinging = ref(false);
    const isCalling = ref(false);
    const isConnected = ref("Disconnected");
    const isMuted = ref(false);
    const isHolded = ref(false);
    const intervalRefresh = ref(true); //To know if it should try to connect in the interval
    let intervalId = null; // To not repeat the interval, and create many intervals.
    const callingNumber = ref("");
    const callRecieved = ref({ callingPartyNumber: "" });
    const presavedCaller = store.state.sip.presavedCaller;
    const lockAnswerCall = ref(false);
    const toast = useToast();
    const isRegistered = store.state.sip.isRegistered;
    const presavedAlarm = computed(() => {
      return store.state.sip.presavedAlarm;
    });
    const unit_user_caller = ref("");
    const transferUser = ref("");

    //Cronometer for calls
    const cronometerRef = ref(null);
    const hours = ref("00");
    const minutes = ref("00");
    const seconds = ref("00");

    // SIPClient
    const webSocketServer = deviceInterphone.iotcomms_master_account.websocket;
    const displayName = ref(deviceInterphone.device_id); //"Web Manuel";
    const password = ref(deviceInterphone.password); //"Lexrwj!K7Cm4QpNS";
    const ha1 = ref(deviceInterphone.ha1); //"5af288df867593f88ca75f2637bc1bfd";
    const user = ref(deviceInterphone.device_id);
    let simpleUser;

    const simpleUserDelegate = {
      onCallCreated: () => {
        console.log(`[${displayName}] Call created`);
        console.log({ simpleUser });
      },
      onCallAnswered: () => {
        isRinging.value = false;
        isCalling.value = false;
        isOnCall.value = true;
        iconColor.value = "orange";
        console.log(`[${displayName}] Call answered`);
        startCronometer();
      },
      onCallHangup: () => {
        isOnCall.value = false;
        isRinging.value = false;
        isCalling.value = false;
        iconColor.value = "white";
        store.dispatch("sip/resetPresavedCaller");
        store.dispatch("sip/resetPresavedAlarm");
        if (simpleUser.session) simpleUser.hangup();
        console.log(`[${displayName}] Call hangup`);
        stopCronometer();
      },
      onCallHold: (held) => {
        isHolded.value = held;
        console.log(`[${displayName}] Call hold ${held}`);
      },
      onRegistered: () => {
        console.log(`[${displayName}] Register`);
      },
      onUnregistered: () => {
        console.log(`[${displayName}] Unregister`);
      },
      onCallReceived: () => {
        console.log(`[${simpleUser.id}] call received`);
        console.log({ simpleUser });
        console.log({ presavedCaller });

        callRecieved.value = {
          callingPartyNumber:
            simpleUser.session.incomingInviteRequest.message.from.uri.normal
              .user,
        };
        if (
          store.state.sip.presavedCaller ==
            simpleUser.session.incomingInviteRequest.message.from.uri.normal
              .user ||
          simpleUser.session.incomingInviteRequest.message.from.uri.normal
            .user == "ivr"
        ) {
          console.log("Llamada auto contestada");
          answerCall();
        } else {
          console.log(
            simpleUser.session.incomingInviteRequest.message.from.uri.normal
              .user
          );
          toast({
            component: ToastificationContent,
            props: {
              title:
                "Nueva Llamada Entrante de " +
                simpleUser.session.incomingInviteRequest.message.from.uri.normal
                  .user,
              icon: "PhoneCallIcon",
              variant: "success",
            },
          });
          isRinging.value = true;
          iconColor.value = "green";
          console.log({ isRinging });
        }
        store
          .dispatch("sip/getDevice", {
            device_id:
              simpleUser.session.incomingInviteRequest.message.from.uri.normal
                .user,
          })
          .then((response) => {
            console.log("Obtener Dispositivo");
            console.log({ response });
            if (response.device?.units) {
              unit_user_caller.value = response.device.units[0].description;
            } else if (response.user) {
              unit_user_caller.value = response.user.email;
            } else {
              unit_user_caller.value =
                simpleUser.session.incomingInviteRequest.message.from.uri.normal.user;
            }
          })
          .catch((e) => {
            console.log({ e });
            toast(
              {
                component: ToastificationContent,
                props: {
                  title:
                    "Ocurrio un error al obtener la información del dispositivo",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              },
              {
                timeout: 10000,
              }
            );
          })
          .finally(() => {
            console.log("Finally");
          });
      },
      onUnregistered: () => {
        iconColor.value = "red";
        isConnected.value = "Unregistered";
        console.log("SIP Server unRegistered");
      },
      onServerDisconnect: (error) => {
        iconColor.value = "red";
        isConnected.value = error;
        console.log("SIP Server Disconnected " + error);
      },
    };

    const createUser = (audioElement) => {
      return new Promise((resolve, reject) => {
        try {
          console.log({ audioElement });
          const simpleUserOptions = {
            delegate: simpleUserDelegate,
            media: {
              remote: {
                audio: audioElement,
              },
            },
            userAgentOptions: {
              //logLevel: "debug",
              displayName: displayName.value,
              authorizationPassword: password.value,
              authorizationHa1: ha1.value,
              authorizationUsername: user.value,
              viaHost: "omniview.mx",
              uri: new URI(
                "sip",
                user.value,
                deviceInterphone.iotcomms_master_account.domain
              ),
              logBuiltinEnabled: false,
              hackViaTcp: false,
            },
          };

          simpleUser = new SimpleUser(webSocketServer, simpleUserOptions);
          overrideOnMessageSipJs(simpleUser)
          resolve(simpleUser);
        } catch (error) {
          reject(error);
        }
      });
    };

    const connect = () => {
      console.log("Connecting SIP");
      simpleUser
        .connect()
        .then(() => {
          isConnected.value = "Connected";
          iconColor.value = "white";
          store.dispatch("sip/setIsRegistered");

          simpleUser
            .register(
              { expires: 600 },
              // {
              //   requestOptions: {
              //     extraHeaders: ["Expires: 100"],
              //   },
              // },
              {
                // An example of how to get access to a SIP response message for custom handling
                requestDelegate: {
                  onReject: (response) => {
                    console.log(`REGISTER rejected`);
                    // let message = `Registration of "${simpleUser.id}" rejected.\n`;
                    // message += `Reason: ${response.message.reasonPhrase}\n`;
                    //alert(message);
                    isConnected.value = "Registration Failed";
                    store.dispatch("sip/unsetIsRegisted");
                  },
                },
              }
            )
            .then(() => {
              store.dispatch("device_interphone/subscribeToForceDisconnect", {
                device_id: deviceInterphone.device_id,
              });
              //alert(`[${simpleUser.id}] registered.\n`);
            })
            .catch((error) => {
              console.error(`[${simpleUser.id}] failed to register`);
              console.error(error);
              //alert(`[${simpleUser.id}] Failed to register.\n` + error);
            });
        })
        .catch((error) => {
          console.error(`[${simpleUser.id}] failed to connect`);
          console.error(error);
          //alert("Failed to connect.\n" + error);
        });
    };

    const unregister = () => {
      simpleUser
        .unregister()
        .then(() => {
          console.log("Entro a UNREGISTER");
        })
        .catch((error) => {
          console.error(`[${simpleUser.id}] failed to unregister`);
          console.error(error);
          //alert(`[${simpleUser.id}] Failed to unregister.\n` + error);
        });
    };

    const hangUp = () => {
      simpleUser.hangup().catch((error) => {
        console.error(`[${simpleUser.id}] failed to hangup call`);
        console.error(error);
        //alert("Failed to hangup call.\n" + error);
      });
    };

    const takeCall = (sessionId, caller, alarm) => {
      lockAnswerCall.value = true;
      store
        .dispatch("sip/takeCall", {
          sessionId,
          user: user.value,
          presavedCaller: caller,
          alarm,
        })
        .then((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((e) => {
          console.log({ e });
          toast(
            {
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        })
        .finally(() => {
          lockAnswerCall.value = false;
          // props.disableEnableButtons(false);
          // props.closeLoading();
        });
    };

       /**
     * Overrides the onMessage method of the SimpleUser's transport(websocket) to modify incoming messages.
     * Specifically in this case handles messages with multiple Record-Route headers by keeping only the first one.
     *
     * @param {SimpleUser} simpleUser - The SimpleUser instance whose onMessage method will be overridden.
     */
     const overrideOnMessageSipJs = (simpleUser) => {
      // Save the original onMessage method
      const originalOnMessage = simpleUser.userAgent.transport.onMessage;

      // Override the onMessage method
      simpleUser.userAgent.transport.onMessage = (message) => {
        //console.log("WebSocket received message:", message);
        // Search for Record-Route headers
        if (message.includes("Record-Route")) {
          const modifiedMessage = message.replace(
            /Record-Route:[^\r\n]*/g,
            (header) => {
              // Delete 'Record-Route:' header before the split
              const routesString = header.replace(/^Record-Route:\s*/, "");
              const routes = routesString.split(",");

              if (routes.length > 1) {
                // Keep just the first Record-Route
                return `Record-Route: ${routes[0].trim()}`;
              }
              return header; // If there is just one Record-Route, return original
            }
          );

          //console.log("Modified Message:", modifiedMessage);
          // Call the original onMessage with the modified message
          return originalOnMessage.call(
            simpleUser.userAgent.transport,
            modifiedMessage
          );
        }

        // Call the original onMessage if no modification is necessary
        return originalOnMessage.call(
          simpleUser.userAgent.transport,
          message
        );
      };
    };

    const answerCall = () => {
      simpleUser.answer().catch((error) => {
        console.error(`[${simpleUser.id}] failed to answer call`);
        console.error(error);
        alert(`[${simpleUser.id}] Failed to answer call.\n` + error);
      });
    };

    const transferCall = () => {
      if (transferUser.value != "") {
        const target = new URI(
          "sip",
          transferUser.value,
          deviceInterphone.iotcomms_master_account.domain
        );
        //TODO: make reactive variable
        let transferSession = simpleUser.session;
        console.log({ transferSession });
        simpleUser.hold();
        console.log(simpleUser.session);
        //Verificar si es .call y este valida si ya hay una sessión activa o .sendInvite por que este es privado
        simpleUser.sendInvite(target);
        simpleUser.session.refer(transferSession);
      }
      transferUser.value = "";
    };

    const holdSession = () => {
      simpleUser.hold();
    };

    const unHoldSession = () => {
      simpleUser.unhold();
    };

    const makeCall = (destination) => {
      console.log("Entro a MakeCall");
      console.log({ isRinging, isOnCall });
      if (isRinging.value || isOnCall.value) {
        toast({
          component: ToastificationContent,
          props: {
            title: "Ya estas en una llamada",
            icon: "PhoneCallIcon",
            variant: "Danger",
          },
        });
        return;
      }
      isCalling.value = true;
      iconColor.value = "yellow";
      console.log("Llamando a:" + destination);
      simpleUser
        .call(destination, {
          inviteWithoutSdp: true,
        })
        .catch((error) => {
          console.error(`[${simpleUser.id}] failed tocall`);
          console.error(error);
          isCalling.value = false;
          iconColor.value = "white";
          // alert(`[${simpleUser.id}] Failed to call.\n` + error);
          simpleUser.hangUp();
        });
    };

    const makeCallWithPresavedCall = (data) => {
      console.log("Entro a makeCallWithPresavedCall");
      console.log(data);
      console.log({ isRinging, isOnCall });
      if (isRinging.value || isOnCall.value) {
        toast({
          component: ToastificationContent,
          props: {
            title: "Ya estas en una llamada",
            icon: "PhoneCallIcon",
            variant: "Danger",
          },
        });
        return;
      }

      store
        .dispatch("sip/setPresavedCall", {
          callingPartyNumber: simpleUser.id,
          remotePartyNumber: data.callingPartyNumber,
          id_alarm: data.id_alarm,
        })
        .then((response) => {
          console.log(response);
          makeCall(
            "sip:" +
              data.callingPartyNumber +
              "@" +
              deviceInterphone.iotcomms_master_account.domain
          );
        })
        .catch((e) => {
          console.log({ e });
          this.showMessage("", e.message, "danger");
        })
        .finally(() => {});
    };

    const mute = () => {
      if (!isMuted.value) {
        isMuted.value = true;
        simpleUser.mute();
      } else {
        isMuted.value = false;
        simpleUser.unmute();
      }
    };

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const setUpSIP = () => {
      let audioElementAgain = document.getElementById("remoteAudio");
      console.log({ audioElementAgain });
      createUser(document.getElementById("remoteAudio"))
        .then((simpleUser) => {
          connect();
        })
        .catch((e) => console.log(e));
    };

    const setUpInterval = () => {
      console.log("Entro a setUpInterval");
      if (intervalId === null) {
        intervalId = setInterval(() => {
          console.log(
            "IntervalConnect: " +
              intervalRefresh.value +
              " isOnCall: " +
              isOnCall.value +
              " isRinging " +
              isRinging.value
          );
          if (!isOnCall.value) {
            if (!isRinging.value) {
              if (intervalRefresh.value) connect();
            }
          }
        }, 180000); //Cada 3.3 min
      }
    };

    const clearSetUpInterval = () => {
      if (intervalId !== null) {
        clearInterval(intervalId);
        intervalId = null;
        console.log("Intervalo eliminado");
      }
    };

    const forceConnectSIP = () => {
      axios.post("/v1/device_interphone/force_disconnect", {
        device_id: deviceInterphone.device_id,
      });
      shouldDisconnect.value = false;
      setTimeout(() => {
        shouldDisconnect.value = true;
      }, 25000); //25 segundos para que se limpie bandera.
      setUpSIP();
      store.dispatch("sip/getAll");
      setUpInterval();
    };

    const disconnectSIP = () => {
      if (simpleUser) {
        let options = {
          requestOptions: {
            extraHeaders: ["Expires: 0"],
          },
        };

        simpleUser
          .unregister(options)
          .then((r) => {
            console.log(r);
          })
          .catch((e) => {
            console.log(e);
          });
        simpleUser.disconnect();
      }
      intervalRefresh.value = false;
      clearSetUpInterval()
      store.dispatch("device_interphone/unSubscribeToForceDisconnect", {
        device_id: deviceInterphone.device_id,
      });
    };

    onMounted(() => {
      console.log("OnMontedSipCallDropdown");
      let audioElementAgain = document.getElementById("remoteAudio");
      store
        .dispatch("sip/getStatus", {
          device_id: user.value,
        })
        .then((response) => {
          if (response.data[0].status != "registered") {
            console.log({ response });
            console.log(response.data[0].status);
            createUser(audioElementAgain)
              .then((simpleUser) => {
                //console.log("WebsocketUrl:" + webSocketServer)
                connect();
              })
              .catch((e) => console.log(e));
            store.dispatch("sip/getAll");
            //store.dispatch("sip/subscribeToCalls");

            setUpInterval();

            EventBus.on(`call_device`, (params) => {
              console.log("on:call_device");
              makeCall(
                "sip:" +
                  params.id +
                  "@" +
                  deviceInterphone.iotcomms_master_account.domain
              );
              callingNumber.value = params.id;
            });

            EventBus.on(`call_device_presaved_call`, (data) => {
              if (isConnected.value != "Disconnected") {
                console.log("on:call_device_presaved_call");
                makeCallWithPresavedCall({ ...data });
                callingNumber.value = data.callingPartyNumber;
              } else {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: "No esta conectada la extensión del usuario",
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                });
              }
            });

            toast({
              component: ToastificationContent,
              props: {
                title: "Sip Registered",
                icon: "CheckIcon",
                variant: "success",
              },
            });
          } else {
            iconColor.value = "red";
            toast({
              component: ToastificationContent,
              props: {
                title: "Ya existe una extension registrada",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((e) => {
          console.log({ e });
          iconColor.value = "red";
          toast(
            {
              component: ToastificationContent,
              props: {
                title: "Ocurrió un error al obtener la extensión",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            },
            {
              timeout: 10000,
            }
          );
        })
        .finally(() => {});

      EventBus.on(`disconnect_sip`, (params) => {
        console.log("on:disconnect_sip");
        console.log("shouldDesconnect:" + shouldDisconnect.value);
        if (shouldDisconnect.value) {
          if (
            isConnected.value == "Connected" &&
            !isOnCall.value &&
            !isRinging.value
          )
            disconnectSIP();
        }
      });
    });

    onUnmounted(() => {
      store.dispatch("sip/unSubscribeToCalls", { user_id: props.UserData.id });
      disconnectSIP();
    });

    window.addEventListener(
      "beforeunload",
      function (e) {
        //if (isConnected.value == "Connected") disconnectSIP();
        disconnectSIP();
      },
      false
    );

    const formatDateAssigned = (value) => {
      const formattedDate = moment(value).format("DD/MM/YYYY HH:mm:ss");
      return formattedDate;
    };

    const alarmAction = async (alarm_call, action) => {
      console.log({ alarm_call, action });
      const alarm = alarm_call.alarmJson;
      console.log({ alarm_call });
      store.dispatch("grid/setSelectedAlarm", { action, alarm }).then(() => {
        EventBus.emit("show-alarm-modal", "alarm_action_modal");
      });
    };

    const alarmShowConfig = async (alarm_call) => {
      console.log({ alarm_call });
      const alarm = alarm_call.alarmJson;
      EventBus.emit("show-alarm-config", alarm);
    };

    const alarmShowRow = async (alarm_call) => {
      console.log({ alarm_call });
      const alarm = alarm_call.alarmJson;
      EventBus.emit("show-alarm-row", alarm);
    };

    EventBus.on(`change-alarm-status`, (params) => {
      console.log(params);
      if (params == "attended" || params == "canceled") {
        console.log("Event Bus Hang Up");
        hangUp();
      }
    });

    const activateRelay = () => {
      console.log(simpleUser.session.sessionDescriptionHandler.sendDtmf(1));
    };

    const chronometer = () => {
      seconds.value++;

      if (seconds.value < 10) seconds.value = `0` + seconds.value;

      if (seconds.value > 59) {
        seconds.value = `00`;
        minutes.value++;

        if (minutes.value < 10) minutes.value = `0` + minutes.value;
      }

      if (minutes.value > 59) {
        minutes.value = `00`;
        hours.value++;

        if (hours.value < 10) hours.value = `0` + hours.value;
      }
    };

    const startCronometer = () => {
      cronometerRef.value = setInterval(chronometer, 1000);
    };

    const stopCronometer = () => {
      cronometerRef.value = clearInterval(cronometerRef.value);
      hours.value = `00`;
      minutes.value = `00`;
      seconds.value = `00`;
    };

    // watch(
    //   () => deviceInterphoneRef,
    //   (newValue, oldValue) => {
    //     if (newValue === oldValue) {
    //       shouldUpdate.value = false; // Evita que el componente se actualice
    //     } else {
    //       shouldUpdate.value = true; // Permite que el componente se actualice para otros cambios en los datos
    //     }
    //   }
    // );

    return {
      calls,
      perfectScrollbarSettings,
      deviceInterphone,
      createUser,
      connect,
      unregister,
      hangUp,
      takeCall,
      setUpSIP,
      isOnCall,
      isConnected,
      isRinging,
      isCalling,
      isMuted,
      iconColor,
      callRecieved,
      answerCall,
      makeCall,
      makeCallWithPresavedCall,
      mute,
      callingNumber,
      formatDateAssigned,
      isRegistered,
      intervalRefresh,
      intervalId,
      disconnectSIP,
      presavedAlarm,
      alarmAction,
      alarmShowConfig,
      lockAnswerCall,
      alarmShowRow,
      cronometerRef,
      seconds,
      minutes,
      hours,
      startCronometer,
      stopCronometer,
      unit_user_caller,
      activateRelay,
      transferUser,
      transferCall,
      holdSession,
      unHoldSession,
      isHolded,
      forceConnectSIP,
      setUpInterval,
      shouldDisconnect,
      clearSetUpInterval
    };
  },
};
</script>

<style lang="scss">
@import "../../../../scss/base/bootstrap-extended/include"; // Bootstrap includes
@import "../../../../scss/base/components/include"; // Components includes
@import "../../../../../assets/scss/variables/variables";

.btn-toggle-config-mode {
  transition: transform, 0.2s;
  &.ringing {
    animation: swing ease-in-out 1s infinite alternate;
    transform-origin: center -20px;
    float: left;
  }

  .ringing:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid #999;
    top: -10px;
    left: 50%;
    z-index: 0;
    border-bottom: none;
    border-right: none;
    transform: rotate(45deg);
  }
  /* nail */
  .ringing:before {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    top: -14px;
    left: 54%;
    z-index: 5;
    border-radius: 50% 50%;
    background: #000;
  }

  @keyframes swing {
    0% {
      transform: rotate(3deg);
    }
    100% {
      transform: rotate(-3deg);
    }
  }
}

.on-call {
  -webkit-animation: flash linear 1s infinite;
  animation: flash linear 1s infinite;
}
@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes flash {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
</style>
